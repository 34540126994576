import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"];

  handleFilterChange(el){
    this._submit(el)
  }

  _submit(el) {
    if (!el.target.value) return;

    this.formTarget.requestSubmit();
  }
}
