import {Controller} from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="registration-progress-query"
export default class extends Controller {
  static targets = ["overlay"];

  connect() {
    document.addEventListener("click", this.handleOutsideClick);
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick);
  }

  open = () => {
    var sideMenu = document.getElementById('notification-overlay');
    sideMenu.classList.remove('right-[-400px]');
    sideMenu.classList.add('right-0');
    document.querySelector('.sidebar-modal').classList.add("modal-overlay");
    const subdomain = sideMenu.dataset.subdomain

    get(`/${subdomain}/employee_menu_notifications`, {
      responseKind: 'turbo-stream'
    })
  }

  close = () => {
    var sideMenu = document.getElementById('notification-overlay');
    sideMenu.classList.remove('right-0');
    sideMenu.classList.add('right-[-400px]');
    document.querySelector('.sidebar-modal').classList.remove("modal-overlay")
  }

  handleOutsideClick = (event) => {
    const overlay = this.overlayTarget;

    if (
      overlay &&
      !overlay.contains(event.target) &&
      !event.target.closest('[data-action="notification-modal#open"]')
    ) {
      this.close();
    }
  };
}
