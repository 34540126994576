import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'fiscalYearName']

  initialize() {
    this.fiscalYearNameTarget.addEventListener("input", () => {
      this.fiscalYearNameTarget.dataset.userEdited = "true";
    });
  }

  selectStartDate() {
    let end_date = new Date(this.startDateTarget.value)
    end_date.setFullYear(end_date.getFullYear() + 1)
    end_date.setDate(end_date.getDate() - 1)

    this.endDateTarget.value = end_date.toISOString().split('T')[0];
  }

  setFiscalYearName() {
    if (this.fiscalYearNameTarget.dataset.userEdited === "true") {
      // do nothing if edited by the user
      return;
    }
  
    const start_date = new Date(this.startDateTarget.value);
    const end_date = new Date(this.endDateTarget.value);
  
    if (start_date.getFullYear() === end_date.getFullYear()) 
    {
      this.fiscalYearNameTarget.value = `${start_date.getFullYear()}`;
    } 
    else 
    {
      this.fiscalYearNameTarget.value = [start_date?.getFullYear(), end_date?.getFullYear()].filter(Boolean).join('/');
    }
  
    this.fiscalYearNameTarget.dataset.userEdited = "false";
  }
}
